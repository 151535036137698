<div class="container second-portion">
  <div class="row">
    <!-- Boxes de Acoes -->
    <div class="col-xs-12 col-sm-6 col-lg-4">
      <div class="box">
        <div class="icon">
          <div class="image"><i class="fa fa-envelope" aria-hidden="true"></i></div>
          <div class="info">
            <h3 class="title">MAIL & WEBSITE</h3>
            <p>
              <i class="fa fa-envelope" aria-hidden="true"></i> info@armanhadi.com
              <br>
              <br>
              <i class="fa fa-globe" aria-hidden="true"></i> armanhadi.com
            </p>

          </div>
        </div>
        <div class="space"></div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-lg-4">
      <div class="box">
        <div class="icon">
          <div class="image"><i class="fa fa-mobile" aria-hidden="true"></i></div>
          <div class="info">
            <h3 class="title">Contact</h3>
            <p>
              <i class="fa fa-mobile" aria-hidden="true"></i> 07503184034


            </p>
          </div>
        </div>
        <div class="space"></div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-lg-4">
      <div class="box">
        <div class="icon">
          <div class="image"><i class="fa fa-map-marker" aria-hidden="true"></i></div>
          <div class="info">
            <h3 class="title">Address</h3>
            <p>
              <i class="fa fa-map-marker" aria-hidden="true"></i> Sulaymaniah - Above Darogha mosque
            </p>
          </div>
        </div>
        <div class="space"></div>
      </div>
    </div>
    <!-- /Boxes de Acoes -->

    <!--My Portfolio  dont Copy this -->

  </div>
</div>
<br><br><br>

<!-- <div class="container">
  <h2 class="section-title wow fadeInUp">Get In Touch</h2>
  <div class="spacer-60"></div>
  <div class="row">
    <div class="col-md-4">
      <div class="contact-info">
        <h3 class="wow fadeInUp">Let's talk about everything!</h3>
        <p class="wow fadeInUp">Don't like forms? Send me an <a href="mailto:name@example.com">email</a>. 👋</p>
      </div>
    </div>
    <div class="col-md-8">
      <form id="contact-form" class="contact-form mt-6" method="post" action="javascript:">
        <div class="messages"></div>
        <div class="row">
          <div class="column col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" name="InputName" id="InputName" placeholder="Your name"
                required="required" data-error="Name is required.">
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="column col-md-6">
            <div class="form-group">
              <input type="email" class="form-control" id="InputEmail" name="InputEmail" placeholder="Email address"
                required="required" data-error="Email is required.">
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="column col-md-12">
            <div class="form-group">
              <input type="text" class="form-control" id="InputSubject" name="InputSubject" placeholder="Subject"
                required="required" data-error="Subject is required.">
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="column col-md-12">
            <div class="form-group">
              <textarea name="InputMessage" id="InputMessage" class="form-control" rows="5" placeholder="Message"
                required="required" data-error="Message is required."></textarea>
              <div class="help-block with-errors"></div>
            </div>
          </div>
        </div>

        <button type="submit" name="submit" id="submit" value="Submit" class="btn btn-default">Send Message</button>
      </form>
    </div>

  </div>

</div> -->
