<div class="container">

    <!-- section title -->
    <h2 class="section-title wow fadeInUp">Services</h2>

    <div class="spacer-60"></div>

    <div class="row">

        <div class="col-md-4">
            <!-- service box -->
            <div class="service-box rounded data-background padding-30 text-center text-light shadow-blue bg-blue">
                <img style="height: 100px;" src="assets/images/mobile-development.svg" alt="UI/UX design" />
                <h3 class="mb-3 mt-0">Mobile Development</h3>
                <p class="mb-0">
                    I can develop a mobile app with the latest technologies. I can also help you with your existing app.

                </p>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>

        <div class="col-md-4">
            <!-- service box -->
            <div class="service-box rounded data-background padding-30 text-center shadow-yellow bg-yellow">
                <img style="height: 100px;" src="assets/images/service-2.svg" alt="UI/UX design" />
                <h3 class="mb-3 mt-0">Web Development</h3>
                <p class="mb-0">
                    I can develop a website for your business. I can also help you with your existing website.
                </p>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>

        <div class="col-md-4">
            <!-- service box -->
            <div class="service-box rounded data-background padding-30 text-center text-light shadow-pink bg-pink">
                <img style="height: 100px;" src="assets/images/back-end-development.svg" alt="Database design" />
                <h3 class="mb-3 mt-0">Database Design</h3>
                <p class="mb-0">
                    I can design a database for your project. I can also help you with your existing database.
                </p>
            </div>
        </div>

    </div>

    <div class="mt-5 text-center">
        <p class="mb-0">Looking for a custom job? <a href="javascript:" (click)="scrollTo('contact')">Click here</a> to contact me! 👋</p>
    </div>

</div>