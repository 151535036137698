<div class="container">

    <!-- section title -->
    <h2 class="section-title wow fadeInUp">About Me</h2>

    <div class="spacer-60"></div>

    <div class="row">

        <div class="col-md-3">
            <div class="text-center text-md-left">
                <!-- avatar image -->
                <img style=" height: 200px; width: 200px; border-radius: 100%;" src="assets/images/profile-pic (1).png" alt="Bolby" />
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>

        <div class="col-md-9 triangle-left-md triangle-top-sm">
            <div class="rounded bg-white shadow-dark padding-30">
                <div class="row">
                    <div class="col-md-6">
                        <!-- about text -->
                        <p>Pleasing Personality, Multitasking Ability, Self-Motivated, Problem solving ability, Adaptable, Flexible and quick learner, Safety conscious, Responsible, Team builder and Team player, having ability</p>
                        <div class="mt-3">
                            <a href="https://firebasestorage.googleapis.com/v0/b/dronline-c2302.appspot.com/o/Arman-Cv-07.pdf?alt=media&token=aff5c3d0-0d24-4b10-9873-88e72d40524f" target="_blacnk" class="btn btn-default">Download CV</a>
                        </div>
                        <div class="spacer-30 d-md-none d-lg-none"></div>
                    </div>
                    <div class="col-md-6">
                        <!-- skill item -->
                        <div class="skill-item">
                            <div class="skill-info clearfix">
                                <h4 class="float-left mb-3 mt-0">Mobile development</h4>
                                <span class="float-right">95%</span>
                            </div>
                            <ngb-progressbar type="warning" [value]="95"></ngb-progressbar>
                            <div class="spacer-20"></div>
                        </div>

                        <!-- skill item -->
                        <div class="skill-item">
                            <div class="skill-info clearfix">
                                <h4 class="float-left mb-3 mt-0">Web development</h4>
                                <span class="float-right">100%</span>
                            </div>
                            <ngb-progressbar type="danger" [value]="100"></ngb-progressbar>
                            <div class="spacer-20"></div>
                        </div>

                        <!-- skill item -->
                        <div class="skill-item">
                            <div class="skill-info clearfix">
                                <h4 class="float-left mb-3 mt-0">Backend design</h4>
                                <span class="float-right">85%</span>
                            </div>
                            <ngb-progressbar type="primary" [value]="85"></ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- row end -->

    <div class="spacer-70"></div>

    <!--  -->

</div>