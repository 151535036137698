<div class="container">

    <!-- section title -->
    <h2 class="section-title wow fadeInUp">Experience</h2>

    <div class="spacer-60"></div>

    <div class="row">

        <div class="col-md-6">

            <!-- timeline wrapper -->
            <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp">
                    <div class="content">
                        <span class="time">2020 - Current</span>
                        <h3 class="title">Wane</h3>
                        <p>Founder of Wane which is a mobile application for online courses
                        </p>
                    </div>
                </div>

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
                    <div class="content">
                        <span class="time">2020 - Current</span>
                        <h3 class="title">Khanoo.com</h3>
                        <p>Founder of Khanoo.com which is a real estate platform .
                        </p>
                    </div>
                </div>

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
                    <div class="content">
                        <span class="time">2021</span>
                        <h3 class="title">51Labs American NGO</h3>
                        <p>Tech advisor at 51labs</p>
                    </div>
                </div>

                <!-- main line -->
                <span class="line"></span>

            </div>

        </div>

        <div class="col-md-6">

            <!-- responsive spacer -->
            <div class="spacer-30 d-md-none d-lg-none"></div>

            <!-- timeline wrapper -->
            <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden">

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp">
                    <div class="content">
                        <span class="time">2018 - 2020</span>
                        <h3 class="title">Sardam Institute</h3>
                        <p>Teaching Web technology and mobile technology at sardam institue.
                        </p>
                    </div>
                </div>

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
                    <div class="content">
                        <span class="time">2017 - 2020</span>
                        <h3 class="title">Komar University</h3>
                        <p>Physics lab assistant at KOMAR UNIVERSITY OF SCIENCE AND TECHNOLOGY managing the lab and laboratory website.</p>
                    </div>
                </div>

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
                    <div class="content">
                        <span class="time">
              2014 - 2017
            </span>
                        <h3 class="title">KAR GROUP</h3>
                        <p>Control room operaotr at KAR group oil and gas company.</p>
                    </div>
                </div>

                <!-- main line -->
                <span class="line"></span>

            </div>

        </div>

    </div>

</div>